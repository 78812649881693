<template>
  <div class="setting-item s-min-max-date">
    <esmp-input
      :label="localSetting.name"
      v-model="minMaxDate"
      :options="options"
    />
  </div>
</template>

<script>
export default {
  name: 'SMinMaxRangeDate',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: Object.freeze({
        type: 'number',
        min: 1,
      }),
    };
  },
  computed: {
    minMaxDate: {
      get() {
        return this.localSetting.value || null;
      },
      set(value) {
        this.localSetting.value = Number(value);
      },
    },
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
